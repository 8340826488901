<template>
  <div class="container-auth">
    <div class="text-left">
      <img
        class="w-100"
        style="max-width: 250px"
        src="@/assets/images/logo.png"
        alt="" />
    </div>
    <h3 class="mt-4" style="color: white; font-size: 36px; font-weight: 300">
      Welcome, <br /><span
        style="color: #f7991c; font-weight: 600; font-size: 30px"
        >it's good that you're here.</span
      >
    </h3>
    <h6 style="color: white; font-size: 18px; font-weight: 300">
      Login with your email and password
    </h6>
    <AlertErrors />
    <div class="form-input mt-4">
      <span>Email</span>
      <b-form-input v-model="form.email" placeholder="example@mail.com" />
    </div>
    <div class="form-input mt-4">
      <span>Password</span>
      <b-form-input
        type="password"
        placeholder="*******"
        v-model="form.password" />
    </div>
    <!-- <div class="d-flex justify-content-center mt-3">
      <vue-recaptcha
        sitekey="6LdNM6UhAAAAAF1naCwFz1zq7KtOwN1vGCEY1Y1d"
        @verify="verifyCaptcha"
        @expired="expiredCaptcha"
        @error="errorCaptcha"
        theme="dark"
      ></vue-recaptcha>
    </div>
    <div class="text-center mb-2">
      <small>{{ captchaMessage }}</small>
    </div> -->
    <div class="mt-4">
      <router-link class="btn btn-link" :to="{ name: 'RecoverPassword' }">
        I forgot my password
      </router-link>
    </div>
    <div class="mt-4 btns">
      <ButtonAction
        @click="onSubmit"
        title="SignIn"
        :loading="loading"
        block="true"
        lg="true"
        :disabled="form.email ==
        null || form.password == null "
        class="btn-yellow" />
    </div>
    <div class="mt-4">
      <router-link class="btn btn-success w-100" :to="{ name: 'Signup' }">
        SignUp
      </router-link>
    </div>
  </div>
</template>
<script>
// import { VueRecaptcha } from "vue-recaptcha";
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  // components: { VueRecaptcha },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      loading: false,
      captchaMessage: null,
      captchaStatus: false,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    onSubmit() {
      this.loading = true;
      this.login(this.form).then((response) => {
        this.loading = false;
        this.$router.push({
          path: '/',
        });
      }).catch(() => {this.loading = false});
    },
  },
};
</script>
<style scoped>
.container-auth {
  max-width: 50%;
  margin: auto;
}
h3 {
  text-align: left;
  font-size: 40px;
  line-height: 48px;
  color: white;
}
h6 {
  text-align: left;
  color: white;
  font-size: 22px;
  font-weight: 200;
  line-height: 32px;
}
.form-input {
  text-align: left;
  color: white;
}

.btns .btn-yellow {
  background: linear-gradient(90deg, #e17126 0%, #f7981c 100%) !important;
}

.btn-link {
  color: #f7991c !important;
  text-align: left !important;
  width: 100% !important;
}
</style>
